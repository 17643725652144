import { configureStore, combineReducers  } from "@reduxjs/toolkit";
import FilesSlice from "./slices/FilesSlice";
import CheckoutSlice from "./slices/CheckoutSlice";
import PaymentModalSlice from "./slices/PaymentModalSlice";
import ModalControlSlice from "./slices/ModalControlSlice";
import UserSlice from "./slices/UserSlice";
import SnackbarSlice from "./slices/SnackbarSlice";
import DeviceSlice from "./slices/deviceSlice";
import CouponSlice from "./slices/CouponSlice"



export const store = configureStore({
  reducer: {
    filesSlice: FilesSlice,
    paymentModalSlice: PaymentModalSlice,
    modalControlSlice: ModalControlSlice,
    userSlice: UserSlice,
    snackbarSlice: SnackbarSlice, 
    deviceSlice: DeviceSlice,
    checkoutSlice: CheckoutSlice,
    couponSlice: CouponSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch; //used for async actions