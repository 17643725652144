// ImageProcessor.js
import React, { useState, useRef, useEffect } from "react";
import { Container, Typography, Divider, Button, Box, CircularProgress } from "@mui/material";
import ImageCanvas from "./ImageCanvas";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/web/pdf_viewer.css";
import { jsPDF } from "jspdf";
import { nextModalPage, closeModal } from "../store/slices/ModalControlSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../axios/axiosInstance";
import { setQuestionsCount } from "../store/slices/CheckoutSlice";
import { showSnackbar } from "../store/slices/SnackbarSlice";

// Configure the PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

function ImageProcessor() {
  const files = useSelector((state) => state.filesSlice.files);
  const filenames = useSelector((state) => state.filesSlice.filenames);
  const pdfFile = files[0]; // Use the PDF from Redux state
  const [combinedImage, setCombinedImage] = useState(null);
  const [transformedImages, setTransformedImages] = useState([]);
  const imageCanvasRef = useRef(null);

  const dispatch = useDispatch();

  const questionsCount = useSelector(
    (state) => state.checkoutSlice.questionsCount
  );

  const handleClick = () => {
    const images = handleProcessSelections(); // Get images directly
    if (images.length === 0) {
      alert("Nenhuma questão selecionada.");
      return;
    }
    dispatch(setQuestionsCount(images.length));
    handleUploadPDF(images); // Pass images to handleUploadPDF
    dispatch(nextModalPage());
  };


  useEffect(() => {
    const loadPDF = async () => {
      if (pdfFile) {
        const typedarray = new Uint8Array(await pdfFile.arrayBuffer());

        const loadingTask = pdfjsLib.getDocument(typedarray);
        const pdf = await loadingTask.promise;
        const numPages = pdf.numPages;
        const pagesArray = [];

        // Set the scale based on the number of pages
        let scale;

        if (numPages >= 1 && numPages <= 10) {
          // scale = 1.5;
          scale = 1.2;
        } else if (numPages > 10 && numPages <= 20) {
          scale = 1;
        } else if (numPages > 20 && numPages <= 30) {
          scale = 0.9;
        } else {
          scale = 0.68;
        }

        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
          const imgData = canvas.toDataURL("image/jpeg");
          pagesArray.push({
            imgData,
            width: canvas.width,
            height: canvas.height,
          });
        }

        // Combine all images into one continuous image
        const totalHeight = pagesArray.reduce(
          (sum, page) => sum + page.height,
          0
        );
        const maxWidth = Math.max(...pagesArray.map((page) => page.width));

        const combinedCanvas = document.createElement("canvas");
        combinedCanvas.width = maxWidth;
        combinedCanvas.height = totalHeight;
        const ctx = combinedCanvas.getContext("2d");

        let currentY = 0;
        for (let page of pagesArray) {
          const img = new Image();
          img.src = page.imgData;
          await new Promise((resolve) => {
            img.onload = () => {
              ctx.drawImage(img, 0, currentY, page.width, page.height);
              currentY += page.height;
              resolve();
            };
          });
        }

        const combinedImgData = combinedCanvas.toDataURL("image/jpeg");
        setCombinedImage(combinedImgData);
      }
    };

    loadPDF();
  }, [pdfFile]);

  const handleProcessSelections = () => {
    if (imageCanvasRef.current) {
      const images = imageCanvasRef.current.getTransformedImages();
      setTransformedImages(images);
      return images; // Return the images directly
    }
    return []; // Return an empty array if no images
  };

  // const handleProcessSelections = () => {
  //   if (imageCanvasRef.current) {
  //     const images = imageCanvasRef.current.getTransformedImages();
  //     setTransformedImages(images);
  //   }
  // };


const handleUploadPDF = async (images) => {
  // if (images.length === 0) {
  //   alert("Nenhuma imagem transformada para baixar.");
  //   return;
  // }

  const doc = new jsPDF();

  images.forEach((imgSrc, index) => {
    const img = new Image();
    img.src = imgSrc;

    doc.addImage(img, "PNG", 10, 10, 190, 0);

    if (index < images.length - 1) {
      doc.addPage();
    }
  });

  // Get the PDF as a Blob
  const pdfBlob = doc.output("blob");

  // Create FormData and append the PDF Blob
  const formData = new FormData();
  formData.append("file", pdfBlob);
  formData.append("filename", filenames[0]);

  // Upload via Axios
  try {
    const response = await axiosInstance.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("File uploaded successfully", response.data);
  } catch (error) {
    dispatch(showSnackbar({ status: 'error', message: 'Houve um erro no upload do arquivo!' }));
    dispatch(closeModal());
    console.error("There was an error uploading the file!", error);
  }
};


  // const handleDownloadPDF = () => {
  //   if (transformedImages.length === 0) {
  //     alert("Nenhuma imagem transformada para baixar.");
  //     return;
  //   }

  //   const doc = new jsPDF();

  //   transformedImages.forEach((imgSrc, index) => {
  //     const img = new Image();
  //     img.src = imgSrc;

  //     doc.addImage(img, "PNG", 10, 10, 190, 0);

  //     if (index < transformedImages.length - 1) {
  //       doc.addPage();
  //     }
  //   });

  //   doc.save("imagens_transformadas.pdf");
  // };

  return (
    <Container>
      {!combinedImage ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
            px: 4,
          }}
        >
          <Typography variant="h6" color="grey">
            Carregando...
          </Typography>
          <CircularProgress size={24} sx={{ mr: 1 }} />
        </Box>
      ) : (
        false
      )}

      <div>
        {combinedImage && (
          <Box mt={1} sx={{ border: "1px solid grey", width: "100%" }}>
            <ImageCanvas ref={imageCanvasRef} src={combinedImage} />
          </Box>
        )}
      </div>

      <Divider sx={{ mt: 1 }} />

      <Box display="flex" alignItems="center">
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", textDecoration: "none" }}
        >
          Questões selecionadas:
        </Typography>
        <Typography variant="body1" sx={{ ml: 2 }} color="grey">
          {questionsCount}
        </Typography>
      </Box>

      <Divider sx={{ mt: 0 }} />

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 1, mb: 1, width: "80%" }}
        onClick={handleClick}
        disabled={questionsCount > 20} // Disable button if questionsCount is greater than 25
      >
        SEGUIR (Máx: 20 questões)
      </Button>

      <Divider sx={{ mt: 0 }} />

      <Typography variant="body2">
        Quer mais questões? Entre em contato pelo e-mail:{" "}
        <u>iloveprovaantiga@gmail.com</u>
      </Typography>
      {/* {combinedImage && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleProcessSelections}
          style={{ marginTop: "20px" }}
        >
          Processar Seleções
        </Button>
      )}
      {transformedImages.length > 0 && (
        <Box mt={4}>
          <Typography variant="h5">Imagens Transformadas</Typography>
          <Typography variant="h6" color="grey">
            Quantidade de imagens: {transformedImages.length}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
            style={{ marginTop: "20px" }}
          >
            Baixar Todas as Imagens em PDF
          </Button>
        </Box>
      )} */}
    </Container>
  );
}

export default ImageProcessor;
